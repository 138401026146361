:root {
  --ion-color-primary: #305c9d;
  --ion-color-primary-rgb: 48, 92, 157;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2a518a;
  --ion-color-primary-tint: #456ca7;
  --ion-color-secondary: #f29d0e;
  --ion-color-secondary-rgb: 242, 157, 14;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d58a0c;
  --ion-color-secondary-tint: #f3a726;
  --ion-color-tertiary: #f0888b;
  --ion-color-tertiary-rgb: 240, 136, 139;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d3787a;
  --ion-color-tertiary-tint: #f29497;
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;
  --ion-color-medium: #383a3e;
  --ion-color-medium-rgb: 56, 58, 62;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #313337;
  --ion-color-medium-tint: #4c4e51;
  --ion-color-light: #9d9fa6;
  --ion-color-light-rgb: 157, 159, 166;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #8a8c92;
  --ion-color-light-tint: #a7a9af;
  --ion-color-cancel: #eeedeb;
  --ion-color-cancel-rgb: 238, 237, 235;
  --ion-color-cancel-contrast: #000000;
  --ion-color-cancel-contrast-rgb: 0, 0, 0;
  --ion-color-cancel-shade: #d1d1cf;
  --ion-color-cancel-tint: #f0efed;
  --ion-color-progress: #659fc7;
  --ion-color-progress-rgb: 101, 159, 199;
  --ion-color-progress-contrast: #ffffff;
  --ion-color-progress-contrast-rgb: 0, 0, 0;
  --ion-color-progress-shade: #598caf;
  --ion-color-progress-tint: #74a9cd;
  --ion-color-accent: #a74887;
  --ion-color-accent-rgb: 167, 72, 135;
  --ion-color-accent-contrast: #ffffff;
  --ion-color-accent-contrast-rgb: 255, 255, 255;
  --ion-color-accent-shade: #933f77;
  --ion-color-accent-tint: #b05a93;
  --ion-color-accent2: #d5deeb;
  --ion-color-accent2-rgb: 213, 222, 235;
  --ion-color-accent2-contrast: #305c9d;
  --ion-color-accent2-contrast-rgb: 0, 0, 0;
  --ion-color-accent2-shade: #bbc3cf;
  --ion-color-accent2-tint: #d9e1ed;
  --ion-color-tag: #6fbf73;
  --ion-color-tag-rgb: 111, 191, 115;
  --ion-color-tag-contrast: #ffffff;
  --ion-color-tag-contrast-rgb: 0, 0, 0;
  --ion-color-tag-shade: #62a865;
  --ion-color-tag-tint: #7dc581;
  --ion-color-quickpay: #ffffff;
  --ion-color-quickpay-rgb: 255, 255, 255;
  --ion-color-quickpay-contrast: #305c9d;
  --ion-color-quickpay-contrast-rgb: 0, 0, 0;
  --ion-color-quickpay-shade: #2a518a;
  --ion-color-quickpay-tint: #456ca7;
  --ion-background-color: #fcfcfc;
  --ion-color-contentbg: #eff1f4;
  --ion-text-color: #666666;
  --ion-border-color: #ddd;
  --ion-star-color: #ffff00;
  --ion-font-color: #000;
  --crud-form-height: 90%;
}
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/* fonts */
@font-face {
  font-family: "bahnschrift";
  src: url("./assets/fonts/bahnschrift.ttf");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("./assets/fonts/Roboto-Italic.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto-Black";
  src: url("./assets/fonts/Roboto-Black.ttf");
}
.fontRegular {
  font-family: "Roboto-Regular";
}

.fontBold {
  font-family: "Roboto-Bold";
}

.fontItalic {
  font-family: "Roboto-Italic";
}

.fontlight {
  font-family: "Roboto-Light";
}

.fontregular {
  font-family: "Roboto-Light";
}

.fontBlack {
  font-family: "Roboto-Black";
}

.VSmallNormal {
  font-size: 0.8rem;
  font-weight: normal;
}

.VVSmallNormal {
  font-size: 0.7rem;
  font-weight: normal;
}

.VVSmallBold {
  font-size: 0.7rem;
  font-weight: bold;
}

.VSmallBold {
  font-size: 0.8rem;
  font-weight: bold;
}

.SmallNormal {
  font-size: 0.9rem;
  font-weight: normal;
}

.SmallBold {
  font-size: 0.9rem;
  font-weight: bold;
}

.MediumNormal {
  font-size: 1.1rem;
  font-weight: normal;
}

.MediumBold {
  font-size: 1.1rem;
  font-weight: bold;
}

.LargeNormal {
  font-size: 1.2rem;
  font-weight: normal;
}

.LargeBold {
  font-size: 1.2rem;
  font-weight: bold;
}

.VLargeNormal {
  font-size: 1.4rem;
  font-weight: normal;
}

.VLargeBold {
  font-size: 1.4rem;
  font-weight: bold;
}

.texttransform {
  text-transform: none;
}

.floatright {
  float: right;
}

.floatleft {
  float: left;
}

.headerlabel {
  font-size: 1.1rem;
  font-weight: normal;
  font-family: "Roboto-Regular";
  color: var(--ion-font-color);
}

.productLabel {
  font-size: 0.8rem;
  font-weight: normal;
  font-family: "Roboto-Regular";
  color: var(--ion-font-color);
  float: left;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.ion-color-accent {
  --ion-color-base: var(--ion-color-accent);
  --ion-color-base-rgb: var(--ion-color-accent-rgb);
  --ion-color-contrast: var(--ion-color-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-accent-shade);
  --ion-color-tint: var(--ion-color-accent-tint);
}

.ion-color-accent2 {
  --ion-color-base: var(--ion-color-accent2);
  --ion-color-base-rgb: var(--ion-color-accent2-rgb);
  --ion-color-contrast: var(--ion-color-accent2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-accent2-contrast-rgb);
  --ion-color-shade: var(--ion-color-accent2-shade);
  --ion-color-tint: var(--ion-color-accent2-tint);
}

.ion-color-tag {
  --ion-color-base: var(--ion-color-tag);
  --ion-color-base-rgb: var(--ion-color-tag-rgb);
  --ion-color-contrast: var(--ion-color-tag-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tag-contrast-rgb);
  --ion-color-shade: var(--ion-color-tag-shade);
  --ion-color-tint: var(--ion-color-tag-tint);
}

.ion-color-quickpay {
  --ion-color-base: var(--ion-color-quickpay);
  --ion-color-base-rgb: var(--ion-color-quickpay-rgb);
  --ion-color-contrast: var(--ion-color-quickpay-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quickpay-contrast-rgb);
  --ion-color-shade: var(--ion-color-quickpay-shade);
  --ion-color-tint: var(--ion-color-quickpay-tint);
}

ion-searchbar ion-icon {
  color: var(--ion-color-primary);
}
ion-searchbar .searchbar-input-container {
  --box-shadow: none;
  border: 1px solid var(--ion-border-color);
}
ion-searchbar .searchbar-input-container input {
  font-size: 0.8rem !important;
}

.Wid100 {
  width: 100%;
}

.Wid90 {
  width: 90%;
}

.active-cart {
  display: block;
  --background: #c8d8e6;
  color: var(--ion-color-primary);
  padding-bottom: 0%;
}
.active-cart ion-icon {
  position: relative;
  color: var(--ion-color-primary);
  margin-left: 8px;
}
.active-cart ion-badge {
  position: absolute;
  top: 2px;
  right: 6px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 100%;
  width: 18px;
  z-index: 100;
  padding-top: 5px !important;
  font-size: 0.6rem;
  color: var(--ion-color-primary);
  background-color: #fff;
}

.headercartbtn {
  display: block;
  color: var(--ion-color-primary);
}
.headercartbtn ion-icon {
  position: relative;
  color: var(--ion-color-primary);
  margin-left: 8px;
}
.headercartbtn ion-badge {
  position: absolute;
  top: 2px;
  right: 6px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 100%;
  width: 18px;
  z-index: 100;
  padding-top: 5px !important;
  font-size: 0.6rem;
  color: var(--ion-color-primary);
  background-color: white;
}

.BlackBorder {
  border: 1px solid black;
}

.activetd {
  color: var(--ion-color-primary) !important;
  background-color: #d2e6fb;
  font-weight: bolder;
}

.rightalign {
  text-align: right !important;
}

.centeralign {
  text-align: center !important;
}

.leftalign {
  text-align: left !important;
}

.Wid90Center {
  width: 90%;
  margin: 0 auto;
}

.fail-toast {
  --color: var(--ion-color-danger);
  --button-color: var(--ion-color-danger);
}

.success-toast {
  --color: var(--ion-color-success);
  --button-color: var(--ion-color-success);
  --background: var(--ion-color-medium);
}

.ThinBorderRadiusGray {
  border-style: solid !important;
  border-width: thin !important;
  border-radius: 5px 5px 5px 5px;
  border-color: lightgrey;
}

.openSansBold {
  font-family: "Open Sans", Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4 !important;
  padding-left: 5px !important;
  display: inline-block;
  color: black;
}

.NoPad {
  margin: 0 auto !important;
  padding: 0 !important;
}

.flexRowbetween {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .prd-productlist {
    max-width: 300px !important;
    width: 300px !important;
    margin: 0 auto;
  }
}